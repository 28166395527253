import {
  AppShell,
  Box,
  Button,
  Flex,
  Header,
  Image,
  LoadingOverlay,
  Modal,
  Navbar,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { TeacherMainAppNavbar } from "../components/TeacherMainApp/TeacherMainAppNavbar";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  blobToFile,
  convertToHyphenSeparated,
  formatDateInDateMonthYear,
} from "../utilities/HelperFunctions";
import {
  GetAllInfoForInstitute,
  GetCourseAccess,
  GetDashboardAccess,
  GetSimulationAccess,
  GetTestAccess,
  IsUserValid,
} from "../_parentsApp/features/instituteSlice";
import { NewTeacherTest } from "./_New/NewTeacherTest";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/ReduxStore";
import { mainPath } from "../store/mainPath.slice";
import { TeacherMainAppFooter } from "../components/TeacherMainApp/TeacherMainAppFooter";
import { MyCoursesPage } from "./_New/MyCoursesPage";
import { getChannelToken } from "../features/videoCall/videoCallSlice";
import { User1 } from "../@types/User";
import { CoursesEmptyPage } from "./_New/CoursesEmptyPage";
import {
  GetCourseFeatureOrderData,
  GetDashboardOrderData,
  GetSimulationOrderData,
  GetTestOrderData,
} from "../_parentsApp/features/paymentSlice";
import { displayRazorpay } from "../utilities/Payment";
import { showNotification } from "@mantine/notifications";
import useParentCommunication from "../hooks/useParentCommunication";
import useFeatureAccess from "../hooks/useFeatureAccess";
import { DashBoardStaff } from "../components/AdminPage/DashBoard/DashBoardStaff/DashBoardStaff";
import { Allsimulations } from "./AllSimulations/AllSimulations";
import { WebsiteBuilder } from "../components/WebsiteBuilder/WebsiteBuilder";
import TitleBar from "../_parentsApp/Components/TitleBar";
import {
  LocalStorageKey,
  RemoveValueFromLocalStorage,
} from "../utilities/LocalstorageUtility";
import { Mixpanel } from "../utilities/Mixpanel/MixpanelHelper";
import { TeacherPageEvents } from "../utilities/Mixpanel/AnalyticeEventTeacherApp";
import { IconRecord, IconRecordStop } from "../components/_Icons/CustonIcons";
import RecordRTC from "recordrtc";
import { LargeFileUploadFrontend } from "../features/fileUpload/FileUpload";
import { UserVideoRecordings } from "./UserVideoRecordings";
import { UserType } from "../components/AdminPage/DashBoard/InstituteBatchesSection";
import { UploadInstituteVideoRecording } from "../_parentsApp/features/videoRecrdingSlice";
import { instituteDetails } from "../store/instituteDetailsSlice";
const maiPathActions = mainPath.actions;
const instituteDetailsActions = instituteDetails.actions;

export enum MainPageTabs {
  DASHBOARD = "Dashboard",
  TEST = "Test",
  TAKECLASS = "Take Class",
  MYCOURSES = "My Courses",
  DOUBTS = "Doubts",
  RESULT = "Result",
  DIARY = "Diary",
  ALL_SIMULATIONS = "All Simulations",
  WEBSITE_BUILDER = "Website Builder",
}

function findEnumValue(value: string): MainPageTabs | undefined {
  for (const key in MainPageTabs) {
    if (
      MainPageTabs[key as keyof typeof MainPageTabs].toLowerCase().trim() ===
      value
    ) {
      return MainPageTabs[key as keyof typeof MainPageTabs];
    }
  }
  return undefined;
}

export function LandingPage() {
  const isMd = useMediaQuery(`(max-width: 820px)`);
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState<MainPageTabs>(
    MainPageTabs.DASHBOARD
  );
  const instituteId = params.id;
  const navbarSelectedItem = params.navbarId;
  const { isReactNativeActive } = useParentCommunication();

  useEffect(() => {
    if (navbarSelectedItem) {
      const value1: any = findEnumValue(navbarSelectedItem);
      setSelectedTab(value1);
      if (value1 === "Dashboard") {
        loadInstituteInformation();
      }
    }
  }, [navbarSelectedItem]);

  const [instituteInfo, setInstituteInfo] =
    useState<InstituteWebsiteDisplay | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainPath1 = useSelector<RootState, string | null>((state) => {
    return state.mainPathSlice.value;
  });
  const dispatch = useDispatch<AppDispatch>();
  function loadInstituteInformation() {
    if (instituteId) {
      setIsLoading(true);
      GetAllInfoForInstitute({ id: instituteId })
        .then((x: any) => {
          setIsLoading(false);
          dispatch(
            instituteDetailsActions.setDetails({
              name: x.name,
              iconUrl: x.schoolIcon,
              _id: x._id,
              secondPhoneNumber: x.secondInstituteNumber,
              address: x.Address,
              phoneNumber: x.institutePhoneNumber,
              featureAccess: x.featureAccess,
              paymentDetailsImageUrl: x.paymentDetailsImageUrl,
              instituteBoard: x.instituteBoard,
            })
          );
          setInstituteInfo(x);
          const mainPath = `/${x.name}/${instituteId}/teach1`;
          dispatch(maiPathActions.setMainPathValue(mainPath));
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    }
  }

  useEffect(() => {
    loadInstituteInformation();
  }, [instituteId]);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const user1 = useSelector<RootState, User1 | null>((state) => {
    return state.currentUser.user;
  });
  useEffect(() => {
    if (user1?._id) {
      IsUserValid({ userId: user1?._id!! })
        .then((data) => {})
        .catch((error) => {
          RemoveValueFromLocalStorage(LocalStorageKey.Token);
          RemoveValueFromLocalStorage(LocalStorageKey.User);
          Mixpanel.track(
            TeacherPageEvents.TEACHER_APP_HOME_PAGE_LOGOUT_SUCCESS
          );
          Mixpanel.logout();
          navigate("/");
        });
    }
  }, [user1]);
  function courseUpgradehandler() {
    if (instituteId) {
      setIsLoading(true);
      GetCourseFeatureOrderData()
        .then((data: any) => {
          setIsLoading(false);
          displayRazorpay(data.order, () => {
            setIsLoading(true);
            GetCourseAccess({ id: instituteId })
              .then(() => {
                showNotification({ message: "success" });
                loadInstituteInformation();
                setIsLoading(false);
              })
              .catch((e) => {
                showNotification({
                  message: "Error Occured.Contact Customer Care",
                  color: "red",
                });
                setIsLoading(false);
                console.log(e);
              });
          });
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }
  function testUpgradehandler() {
    if (instituteId) {
      setIsLoading(true);
      GetTestOrderData()
        .then((data: any) => {
          setIsLoading(false);
          displayRazorpay(data.order, () => {
            setIsLoading(true);
            GetTestAccess({ id: instituteId })
              .then(() => {
                showNotification({ message: "success" });
                loadInstituteInformation();
                setIsLoading(false);
              })
              .catch((e) => {
                showNotification({
                  message: "Error Occured.Contact Customer Care",
                  color: "red",
                });
                setIsLoading(false);
                console.log(e);
              });
          });
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }
  function simulationUpgrade() {
    if (instituteId) {
      setIsLoading(true);
      GetSimulationOrderData()
        .then((data: any) => {
          setIsLoading(false);
          displayRazorpay(data.order, () => {
            setIsLoading(true);
            GetSimulationAccess({ id: instituteId })
              .then(() => {
                showNotification({ message: "success" });
                loadInstituteInformation();
                setIsLoading(false);
              })
              .catch((e) => {
                showNotification({
                  message: "Error Occured.Contact Customer Care",
                  color: "red",
                });
                setIsLoading(false);
                console.log(e);
              });
          });
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }
  function dashboardUpgradehandler() {
    if (instituteId) {
      setIsLoading(true);
      GetDashboardOrderData()
        .then((data: any) => {
          setIsLoading(false);
          displayRazorpay(data.order, () => {
            setIsLoading(true);
            GetDashboardAccess({ id: instituteId })
              .then(() => {
                showNotification({ message: "success" });
                loadInstituteInformation();
                setIsLoading(false);
              })
              .catch((e) => {
                showNotification({
                  message: "Error Occured.Contact Customer Care",
                  color: "red",
                });
                setIsLoading(false);
                console.log(e);
              });
          });
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    if (navbarSelectedItem === MainPageTabs.TAKECLASS.toLowerCase().trim())
      document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [navbarSelectedItem]);

  const navigate = useNavigate();
  const {
    isFeatureValid,
    UserFeature,
    isFeatureValidwithNotification,
    shownotification,
  } = useFeatureAccess();

  function teacherLogout() {
    if (isReactNativeActive() && instituteInfo) {
      navigate(`/${instituteInfo.name}/${instituteInfo._id}/parent`);
    } else {
      navigate("/");
    }
  }

  const [openToggle, setOpenToggle] = useState<boolean>(false);
  const [startRecording, setStartRecording] = useState<boolean>(false);
  const [openRecordingModel, setOpenRecordingModel] = useState<boolean>(false);
  const mediaRecorderRef = useRef<any>(null);
  const [recordedBlob, setRecordedBlob] = useState<File | null>(null);
  let combinedStream: any;

  const instituteDetails = useSelector<RootState, InstituteDetails | null>(
    (state) => state.instituteDetailsSlice.instituteDetails
  );

  const user = useSelector<RootState, User1 | null>((state) => {
    return state.currentUser.user;
  });

  const handleStartRecording = async () => {
    setStartRecording(true);
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true, // Request system audio
      });
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true, // Request microphone audio
      });
      const videoTrack = stream.getVideoTracks()[0];
      videoTrack.onended = () => {
        handleStopRecording();
      };
      const combinedStream = new MediaStream([
        ...stream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);
      const recorder = new RecordRTC(combinedStream, {
        type: "video",
        mimeType: "video/webm",
        timeSlice: 3 * 60 * 60 * 1000,
      });

      recorder.startRecording();

      mediaRecorderRef.current = recorder;
      setStartRecording(true);
    } catch (err) {
      console.error("Error starting recording:", err);
      setStartRecording(false);
    }
  };


  const handleStopRecording = () => {
    setStartRecording(false);
    if (mediaRecorderRef && mediaRecorderRef.current) {
      mediaRecorderRef.current.stopRecording(() => {
        const blob = mediaRecorderRef.current.getBlob();
        const fileName = `${formatDateInDateMonthYear(new Date(Date.now()))}_Video-Recording_${Date.now()}_${instituteDetails?.name??""}`;
        const file = blobToFile(blob, fileName);
        setRecordedBlob(file);
        setIsLoading(true);
        LargeFileUploadFrontend({ file: file }, (progress: number) => {})
          .then((x) => {
            UploadInstituteVideoRecording({
              url: x.url.Location,
              name: fileName,
              id: instituteDetails?._id!!,
              userId: user?._id!!,
            })
              .then((x) => {
                showNotification({ message: "Video recorded succesfuly!" });
                setIsLoading(false);
                showNotification({
                  message: "Recording saved successfully",
                });
                mediaRecorderRef.current = null;
              })
              .catch((e) => {
                setIsLoading(false);
                mediaRecorderRef.current = null;
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
        setStartRecording(false);
        if (combinedStream) {
          const tracks = combinedStream.getTracks();
          tracks.forEach((track: any) => {
            track.stop();
          });
        }
      });
    }
  };

  if (instituteInfo && instituteId && mainPath1)
    return (
      <AppShell
        styles={{
          main: {
            width: "75vw",
          },
        }}
        navbar={
          isMd ? (
            <></>
          ) : (
            <TeacherMainAppNavbar
              selectedTab={selectedTab}
              instituteId={instituteId}
              instituteName={convertToHyphenSeparated(
                instituteInfo?.name ?? ""
              )}
              mainPath={mainPath1}
              schoolIcon={instituteInfo?.schoolIcon}
              schoolName={instituteInfo.name}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              isSchool={instituteInfo.isSchool}
              userRole={user1?.role ?? ""}
              showVideoRecord={() => {
                setOpenRecordingModel(true);
              }}
            />
          )
        }
        header={
          <>
            {isMd && instituteInfo != null && (
              <Header height={60}>
                <TitleBar
                  schoolName={instituteInfo?.name}
                  schoolIcon={instituteInfo?.schoolIcon}
                  isTeacher={false}
                  isParent={true}
                  instituteId={instituteId}
                  name={user1?.name}
                  onLogout={() => {
                    navigate("/");
                  }}
                  mainPath={""}
                  showDoubts={false}
                  profilePic=""
                  showVideoRecord={() => {
                    setOpenRecordingModel(true);
                  }}
                />
              </Header>
            )}
          </>
        }
        footer={
          isMd ? (
            <TeacherMainAppFooter
              selectedTab={selectedTab}
              instituteId={instituteId}
              instituteName={convertToHyphenSeparated(instituteInfo?.name)}
              mainPath={mainPath1}
              isSchool={instituteInfo.isSchool}
              userRole={user1?.role ?? ""}
            />
          ) : (
            <></>
          )
        }
        padding={0}
      >
        <LoadingOverlay visible={isLoading} />

        <Box
          ml={isMd ? 0 : isCollapsed ? 75 : 250}
          w={isMd ? "100%" : `calc(100% - ${isCollapsed ? "75px" : "250px"})`}
          mih={"100vh"}
          style={{
            position: "relative",
            transition: "margin ease-in-out 250ms",
          }}
        >
          <Box id="your-scroll-area-id" w={"100%"} h={"100%"}>
            <Flex
              align={"center"}
              justify={"end"}
              p={2}
              sx={{
                position: "fixed",
                top: "50%",
                left: openToggle
                  ? isMd
                    ? "78.5%"
                    : "94.5%"
                  : isMd
                  ? "92%"
                  : "98%",
                zIndex: 123456,
              }}
            >
              <Flex
                align={"center"}
                justify={"end"}
                p={2}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: openToggle
                    ? isMd
                      ? "85%"
                      : "96%"
                    : isMd
                    ? "94%"
                    : "98%",
                  zIndex: 123456,
                }}
              >
                <Box
                  w={28}
                  h={28}
                  bg={"white"}
                  sx={{
                    border: "1px thin gray",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                >
                  <Image
                    src={require("../assets/arrow-down.png")}
                    onClick={() => setOpenToggle(!openToggle)}
                    sx={{
                      transform: openToggle
                        ? "rotate(-90deg)"
                        : "rotate(90deg)",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                    title="Start recording"
                  />
                </Box>
                {openToggle && (
                  <Flex bg={"white"} p={3} w={48} h={44}>
                    <Box
                      w={44}
                      mx={"auto"}
                      p={startRecording ? 2 : 4}
                      sx={{ border: "1px solid #1f7ed0", borderRadius: 2 }}
                    >
                      {startRecording ? (
                        <Flex
                        h={30}
                          align={"center"}
                          justify={"center"}
                          sx={{ cursor: "pointer" }}
                          onClick={handleStopRecording}
                        >
                          <IconRecordStop />
                        </Flex>
                      ) : (
                        <Flex
                        h={30}
                          align={"center"}
                          justify={"center"}
                          sx={{ cursor: "pointer" }}
                          onClick={handleStartRecording}
                          m={"auto"}
                        >
                          <IconRecord col="#1f7ed0" />
                        </Flex>
                      )}
                    </Box>
                  </Flex>
                )}
              </Flex>
            </Flex>

            {navbarSelectedItem ===
              MainPageTabs.DASHBOARD.toLowerCase().trim() && (
              <>
                {instituteInfo.featureAccess.dashboardFeature === true && (
                  <DashBoardStaff instituteId={instituteInfo._id} />
                )}
                {instituteInfo.featureAccess.dashboardFeature === false && (
                  <CoursesEmptyPage
                    instituteId={instituteInfo._id}
                    heading={"Manage, Collect, and Showcase with Ease"}
                    subheading={
                      "Experience Seamless Management and Empower Your Institution's Growth"
                    }
                    img={require("../assets/upgradedashboard.png")}
                    onClick={() => {
                      dashboardUpgradehandler();
                    }}
                    onLogout={teacherLogout}
                  />
                )}
              </>
            )}
            {navbarSelectedItem ===
              MainPageTabs.ALL_SIMULATIONS.toLowerCase().trim() && (
              <>
                {instituteInfo.featureAccess.simualtionAccess &&
                  isFeatureValid(UserFeature.SIMULATIONS) === true && (
                    <Allsimulations />
                  )}
                {(instituteInfo.featureAccess.simualtionAccess === false ||
                  isFeatureValid(UserFeature.SIMULATIONS) === false) && (
                  <CoursesEmptyPage
                    instituteId={instituteInfo._id}
                    heading={
                      "Enhance Your Teaching Through Interactive Simulations"
                    }
                    subheading={
                      "Create, Share, and Explore Personalized Simulations Online and Offline. Upgrade to Premium for Advanced Features and Detailed Insights."
                    }
                    img={require("../assets/simulationPremium.png")}
                    onClick={() => {
                      if (
                        instituteInfo.featureAccess.simualtionAccess === false
                      ) {
                        simulationUpgrade();
                      } else {
                        shownotification();
                      }
                    }}
                    onLogout={teacherLogout}
                  />
                )}
              </>
            )}
            {navbarSelectedItem ===
              MainPageTabs.WEBSITE_BUILDER.toLowerCase().trim() && (
              <WebsiteBuilder
                setSelectedSection={(val) => {}}
                setIsLoading={setIsLoading}
                instituteDetails={instituteInfo}
                reloadInstituteData={() => {
                  loadInstituteInformation();
                }}
                setSelectedNotice={() => {}}
              />
            )}

            {navbarSelectedItem === MainPageTabs.TEST.toLowerCase().trim() && (
              <>
                {instituteInfo.featureAccess.testFeatureService &&
                  isFeatureValid(UserFeature.TESTINGPLATFORM) === true && (
                    <NewTeacherTest onlogout={teacherLogout} />
                  )}
                {(instituteInfo.featureAccess.testFeatureService === false ||
                  isFeatureValid(UserFeature.TESTINGPLATFORM) === false) && (
                  <CoursesEmptyPage
                    instituteId={instituteInfo._id}
                    heading={"Empower Your Testing Experience"}
                    subheading={
                      "Create, Share, and Analyze Personalized Tests Online and Offline. Upgrade to Premium for Enhanced Features and Comprehensive Reports."
                    }
                    img={require("../assets/upgradeTest.png")}
                    onClick={() => {
                      if (
                        instituteInfo.featureAccess.testFeatureService === false
                      ) {
                        testUpgradehandler();
                      } else {
                        shownotification();
                      }
                    }}
                    onLogout={teacherLogout}
                  />
                )}
              </>
            )}
            {navbarSelectedItem ===
              MainPageTabs.MYCOURSES.toLowerCase().trim() && (
              <>
                {instituteInfo.featureAccess.course === true &&
                  isFeatureValid(UserFeature.ONLINECOURSES) === true && (
                    <MyCoursesPage onLogout={teacherLogout} />
                  )}
                {(instituteInfo.featureAccess.course === false ||
                  isFeatureValid(UserFeature.ONLINECOURSES) === false) && (
                  <CoursesEmptyPage
                    instituteId={instituteInfo._id}
                    heading={
                      "Empowering Users: Introducing Course Creation Functionality"
                    }
                    subheading={"Create and share your own customized courses"}
                    img={require("../assets/emptycoursePage.png")}
                    onClick={() => {
                      if (instituteInfo.featureAccess.course === false) {
                        courseUpgradehandler();
                      } else {
                        shownotification();
                      }
                    }}
                    onLogout={teacherLogout}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <Modal
          opened={openRecordingModel}
          onClose={() => setOpenRecordingModel(false)}
          title={"Your recordings"}
          fw={700}
          fz={24}
          fullScreen
          zIndex={1234}
        >
          <UserVideoRecordings
            userId={user?._id!!}
            userType={UserType.OTHERS}
            isVideoSelect={false}
          />
        </Modal>
      </AppShell>
    );
  else return <></>;
}
