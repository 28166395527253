import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  useMantineTheme,
  Stack,
  Center,
  SimpleGrid,
  Text,
  Button,
  Paper,
  Box,
  Flex,
  Select,
  Modal,
  TextInput,
  LoadingOverlay,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/ReduxStore";
import { WebAppEvents } from "../../../utilities/Mixpanel/AnalyticeEventWebApp";
import { Mixpanel } from "../../../utilities/Mixpanel/MixpanelHelper";
import { subjects } from "../../../store/subjectsSlice";
import { SubjectCard } from "./SubjectCard";
import { createCustomChapter } from "../../../features/userChapter/userChapterSlice";
import { GetBoardClasses } from "../../../_parentsApp/features/instituteSlice";
import { GetSubjectsFromClass } from "../../../features/classes/classSlice";

const subjectsActions = subjects.actions;

interface ClassesSubjectsProps {
  subjects: UserSubject[];
}

const SelectClass = (props: {
  batchId: string;
  selectedBoardId: string;
  userClassesSubjects: UserClassAndSubjects[];
  onSubjectClicked: (currentSubject: UserSubject) => void;
  onAddCustomChapter: (val: AddCustomChapter) => void;
  onSetBoardId: (val: string) => void;
}) => {
  const instituteDetails = useSelector<RootState, InstituteDetails | null>(
    (state: any) => state.instituteDetailsSlice.instituteDetails
  );
  const [opened, setOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [boardId, setBoardId] = useState<string>("");
  const [addCustomChapter, setAddCustomChapter] = useState<{
    class: { id: string; name: string } | null;
    subject: { id: string; name: string } | null;
    chapterName: string;
  }>({
    class: null,
    subject: null,
    chapterName: "",
  });
  const [selectedClass, setSelectedClass] = useState<string>(
    props.userClassesSubjects[0]?.className
  );
  const [selectedBoard, setSelectedBoard] = useState<string>("");
  const [selectedBoardValues, setSelectedBoardValues] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [classData, setClassData] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const [subjectData, setSubjectData] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [selectedClasses, setSelectedClasses] = useState<
    UserClassAndSubjects[]
  >([]);
  const theme = useMantineTheme();
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [subjectsForSelectedClass,setSubjectsForSelectedClass] = useState<UserSubject[]>()


  useEffect(() => {
    if (props.userClassesSubjects.length > 0) {
      if(!props.selectedBoardId){
        setSelectedBoard(props.userClassesSubjects[0]?.boardId!!);
      } else {
        setSelectedBoard(props.selectedBoardId)
      }
      setSelectedBoardValues(
        props.userClassesSubjects
          .map((x) => {
            return { value: x.boardId!!, label: x.boardName!! };
          }).filter((x, i, a) => a.findIndex((y) => y.value === x.value) === i)
      );
    }
  }, [props.userClassesSubjects]);

  const handleClassChange = (className: string) => {
    setSelectedClass(className);
  };
  useEffect(() => {
    setSelectedClass(props.userClassesSubjects[0]?.className);
  }, [props.userClassesSubjects]);

  useEffect(() => {
    setSelectedClass(selectedClasses[0]?.className);
  }, [selectedClasses]);

  useEffect(() => {
    const currentSubjects = props.userClassesSubjects.filter(
      (userClassSubj) => userClassSubj.boardId === selectedBoard
    )
    if(currentSubjects.length === 0){
      const subjectsForSelectedClass =
    props.userClassesSubjects.find(
      (userClassSubj) => userClassSubj.className === selectedClass
    )?.subjects || [];
    setSubjectsForSelectedClass(subjectsForSelectedClass)
    } else {
      setSubjectsForSelectedClass(currentSubjects[0].subjects || [])
    }
    setSelectedClasses(currentSubjects);
    props.onSetBoardId(selectedBoard);
  }, [selectedBoard]);

  const handleInputChange = (field: string, value: any) => {
    setAddCustomChapter((prev) => ({ ...prev, [field]: value }));
  };

  const onClickCreateCustomChapter = () => {
    setIsLoading(true);
    createCustomChapter({
      name: instituteDetails?.name!!,
      classData: {
        id: addCustomChapter.class?.id || "",
        name: addCustomChapter.class?.name || "",
      },
      subject: {
        id: addCustomChapter.subject?.id || "",
        name: addCustomChapter.subject?.name || "",
      },
      userChapter: addCustomChapter.chapterName,
      id: instituteDetails?._id!!,
      boardId: boardId,
      batchId: props.batchId!!,
    })
      .then((x: any) => {
        setIsLoading(false);
        props.onAddCustomChapter(x.chapterResponse);
        setOpened(false);
        setAddCustomChapter({
          class: null,
          subject: null,
          chapterName: "",
        });
      })
      .catch((e) => {
        setIsLoading(false);
        setOpened(false);
        console.log(e);
      });
  };

  const onClickAddCustomChapter = () => {
    setOpened(true);
    GetBoardClasses(instituteDetails?._id!!)
      .then((x: any) => {
        let extractedData: {
          value: string;
          label: string;
        }[] = [];

        extractedData = x.classes.map((b: any) => ({
          value: b._id,
          label: b.name,
        }));
        setBoardId(x._id);
        setClassData(extractedData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (addCustomChapter.class?.id && addCustomChapter.class?.id !== "0") {
      GetSubjectsFromClass(addCustomChapter.class?.id)
        .then((x: any) => {
          let extractedData: {
            value: string;
            label: string;
          }[] = [];

          extractedData = x.userSubjects.map((b: any) => ({
            value: b._id,
            label: b.name,
          }));
          setSubjectData(extractedData);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setAddCustomChapter(prevState => ({
        ...prevState,
        subject: null,
      }));
      setSubjectData([]);
    }
  }, [addCustomChapter.class]);

  return (
    <Stack w="100%">
      <LoadingOverlay visible={isLoading} />
      <Flex justify="space-between" align={"end"}  p={3}>
        <Box w={"40%"}>
          <Button variant="outline" onClick={onClickAddCustomChapter}>
            + Add Custom Chapter
          </Button>
        </Box>
        <Flex w={"40%"} align={"end"} justify={"end"} >
        <Select
            label="Change board"
            value={props.selectedBoardId}
            onChange={(val) => {
              if (val) setSelectedBoard(val);
            }}
            style={{ width: isMd?"150px":"200px" }}
            placeholder="Select Board"
            data={selectedBoardValues}
          />
        </Flex>
      </Flex>
      {selectedClasses.length > 0 && (
        <Paper
          style={{
            borderRadius: "24px",
            border: "4px solid #C9DFFB",
          }}
          w="100%"
        >
          <Stack w={"100%"}>
            <Flex
              style={{
                gap: "12px",
                borderBottom: "4px solid #C9DFFB",
                marginBottom: "-16px",
              }}
              w="100%"
              wrap="wrap"
              p={20}
            >
              {selectedClasses
                .map((x) => {
                  return x.className;
                })
                .map((className) => (
                  <div key={className} style={{ position: "relative" }}>
                    <Button
                      bg={className === selectedClass ? "#4B65F6" : "white"}
                      c={className === selectedClass ? "white" : "#4B65F6"}
                      ref={className === selectedClass ? buttonRef : null}
                      onClick={() => {
                        Mixpanel.track(
                          WebAppEvents.TEACHER_APP_HOME_PAGE_CLASS_FILTER_CLICKED,
                          {
                            class_id: props.userClassesSubjects.find(
                              (userClassSubj) =>
                                userClassSubj.className === selectedClass
                            )?.classId,
                          }
                        );
                        handleClassChange(className);
                      }}
                      variant={
                        className === selectedClass ? "filled" : "outline"
                      }
                      style={{
                        marginBottom: "8px",
                        fontSize: isMd ? "12px" : "16px",
                        padding: isMd ? "8px 8px" : "10px 10px",
                        position: "relative",
                      }}
                      styles={{
                        root: {
                          borderColor: "#4B65F6",
                          " &:hover": {
                            backgroundColor:
                              className === selectedClass ? "#4B65F6" : "white",
                          },
                        },
                      }}
                    >
                      {className === selectedClass && (
                        <div
                          style={{
                            height: "3px",
                            backgroundColor: "#4B65F6",
                            position: "absolute",
                            top: -12,
                            left: 0,
                          }}
                        ></div>
                      )}
                      {className}
                    </Button>
                  </div>
                ))}
            </Flex>

            <Stack
              p={10}
              px={20}
              style={{
                borderRadius: isMd ? 8 : 16,
              }}
            >
              <Text fz={24} fw={700} mb={-15}>
                Subjects
              </Text>
              <SimpleGrid
                cols={6}
                breakpoints={[
                  { maxWidth: "xl", cols: 6, spacing: "md" },
                  { maxWidth: "lg", cols: 5, spacing: "md" },
                  { maxWidth: "md", cols: 4, spacing: "md" },
                  { maxWidth: "sm", cols: 3, spacing: "sm" },
                  { maxWidth: "xs", cols: 2, spacing: "sm" },
                ]}
              >
                {subjectsForSelectedClass && subjectsForSelectedClass.map((x) => (
                  <SubjectCard
                    subject={x}
                    onClick={() => {
                      Mixpanel.track(
                        WebAppEvents.TEACHER_APP_SUBJECT_CARD_CLICKED,
                        {
                          subject_id: x.name,
                        }
                      );
                      props.onSubjectClicked(x);
                    }}
                    key={x._id}
                  />
                ))}
              </SimpleGrid>
            </Stack>

            <Stack
              p={isMd ? 15 : 30}
              px={20}
              py={4}
              style={{
                position: "relative",
              }}
            >
              <Box
                style={{
                  width: "165px",
                  height: "37px",
                  flexShrink: 0,
                  borderRadius: "0px 24px 0px 10px",
                  background: "#C9DFFB",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              ></Box>
            </Stack>
          </Stack>
        </Paper>
      )}

      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
          setAddCustomChapter({
            class: null,
            subject: null,
            chapterName: "",
          });
        }}
        title="Add custom chapter"
        centered
        fw={700}
      >
        <Stack align="start" justify="center" spacing={10}>
          <Select
            w={"80%"}
            label="Class"
            placeholder="Select or create a class"
            data={classData}
            searchable
            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const newClass = { value: "0", label: query };
              setClassData((current) => [...current, newClass]);
              handleInputChange("class", {
                id: newClass.value,
                name: newClass.label,
              });

              return newClass.value;
            }}
            value={addCustomChapter.class?.id}
            onChange={(value) => {
              const selectedClass = classData.find(
                (item) => item.value === value
              );
              if (selectedClass) {
                handleInputChange("class", {
                  id: selectedClass.value,
                  name: selectedClass.label,
                });
              }
            }}
          />

          <Select
            w={"80%"}
            label="Subject"
            placeholder="Select or create a subject"
            data={subjectData}
            searchable
            creatable
            disabled={!addCustomChapter.class?.name}
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const newSubject = { value: "0", label: query };
              setSubjectData((current) => [...current, newSubject]);
              handleInputChange("subject", {
                id: newSubject.value,
                name: newSubject.label,
              });
              return newSubject.value;
            }}
            value={addCustomChapter.subject?.id || null}
            onChange={(value) => {
              const selectedSubject = subjectData.find(
                (item) => item.value === value
              );
              if (selectedSubject) {
                handleInputChange("subject", {
                  id: selectedSubject.value,
                  name: selectedSubject.label,
                });
              }
            }}
          />

          <TextInput
            w={"80%"}
            label="Chapter "
            disabled={addCustomChapter.subject?.name ? false : true}
            placeholder="Enter Chapter name"
            value={addCustomChapter.chapterName}
            onChange={(e) => handleInputChange("chapterName", e.target.value)}
          />
          <Button
            disabled={!addCustomChapter.chapterName}
            onClick={onClickCreateCustomChapter}
          >
            Add
          </Button>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default SelectClass;
