import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  Group,
  LoadingOverlay,
  Menu,
  Modal,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconDotsVertical, IconPlus } from "@tabler/icons";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";
import { RootState } from "../store/ReduxStore";
import { deleteRecording } from "../features/recordingsSlice";
import { UserType } from "../components/AdminPage/DashBoard/InstituteBatchesSection";
import {
  DeleteUserVideoRecordings,
  GetUserVideoRecordings,
} from "../_parentsApp/features/videoRecrdingSlice";
import { GetAllCoursesForInstitute } from "../_parentsApp/features/instituteSlice";
import { addVideoToCourse } from "../features/course/courseSlice";

function VideoCard(props: {
  video: CourseVideo;
  onVideoPlayClicked: () => void;
  addVideoToCourse: (video:CourseVideo) => void;
  onDeleteVideoClicked: () => void;
  showOptions: boolean;
  selectedVideos: {
    name: string;
    url: string;
  }[];
  isVideoSelect: boolean;
  onSelectRecording: (
    val: {
      name: string;
      url: string;
    },
    isChecked: boolean
  ) => void;
}) {
  const handleDownloadClick = (videoUrl: string) => {
    const link = document.createElement("a");
    link.href = videoUrl;
    link.download = "video.mp4";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Stack
      style={{
        boxShadow: "0px 0px 16px 0px #00000040",
        borderRadius: "20px",
        cursor: "pointer",
      }}
      spacing={0}
      m={20}
    >
      <img
        src={require("../assets/emptyvideoImage.jpg")}
        style={{
          width: "100%",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
        alt="video"
        onClick={props.onVideoPlayClicked}
      />
      <Flex
        justify="space-between"
        w="100%"
        align="center"
        gap={5}
        py={20}
        px={20}
      >
        {props.isVideoSelect && (
          <Checkbox
            value={props.video.url}
            onChange={(e) =>
              props.onSelectRecording({name:props.video.name,url:props.video.url}, e.currentTarget.checked)
            }
          />
        )}
        <Text
          fz={16}
          fw={700}
          w="100%"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.video.name}-
          {format(new Date(props.video.createdAt), "dd MMMM yyyy")}
        </Text>
        {props.showOptions && (
          <>
            <Menu>
              <Menu.Target>
                <Button compact variant="subtle" c={"gray"}>
                  <IconDotsVertical style={{ cursor: "pointer" }} />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => {
                    props.addVideoToCourse(props.video);
                  }}
                >
                  Add to Course
                </Menu.Item>
                <Menu.Item onClick={() => handleDownloadClick(props.video.url)}>
                  Download
                </Menu.Item>

                <Menu.Item
                  onClick={() => {
                    props.onDeleteVideoClicked();
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </>
        )}
      </Flex>
    </Stack>
  );
}
export function UserVideoRecordings(props: {
  userId: string;
  userType: UserType;
  isVideoSelect: boolean;
  onClickSubmit?: (selectedVideos:    {
    name: string;
    url: string;
  }[]) => void;
}) {
  const [allRecordings, setAllRecordings] = useState<CourseVideo[]>([]);
  const [playVideo, setPlayVideo] = useState<CourseVideo | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<CourseVideo | null>(null);
  const [selectedVideos, setSelectedVideos] = useState<
    {
      name: string;
      url: string;
    }[]
  >([]);
  const [allCourses, setAllCourses] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [selectedCourse, setSelectedCourse] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
  const [selectedDeletedVideoId, setSelectedDeletedVideoId] = useState<
    string | null
  >();

  const theme = useMantineTheme();
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const instituteDetails = useSelector<RootState, InstituteDetails | null>(
    (state) => state.instituteDetailsSlice.instituteDetails
  );

  useEffect(() => {
    if (props.userId) {
      setIsLoading(true);
      GetUserVideoRecordings(props.userId)
        .then((x: any) => {
          const allVideos = x.map((v: any) => ({
            _id: v._id,
            name: v.name,
            description: "",
            thumbnail: "",
            url: v.videoUrl,
            createdAt: v.createdAt,
          }));
          setAllRecordings(allVideos);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [props.userId]);

  useEffect(() => {
    GetAllCoursesForInstitute({
      id: instituteDetails?._id ?? "",
    })
      .then((x: any) => {
        setAllCourses(x);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [instituteDetails]);

  function handleSubmit() {
    setIsLoading(true);
    addVideoToCourse({
      courseId: selectedCourse,
      videoId: selectedVideo?._id!!,
    })
      .then((x) => {
        setIsLoading(false);
        showNotification({
          message: `Video Added to ${
            allCourses.find((x) => x.value === selectedCourse)?.label
          }`,
        });
        setSelectedVideo(null);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }

  function handleRecordingDelete(id: string) {
    setIsLoading(true);
    DeleteUserVideoRecordings(id)
      .then((x) => {
        setIsLoading(false);
        showNotification({
          message: `Recording Deleted`,
        });
        setAllRecordings(allRecordings.filter((v) => v._id !== id));
        setSelectedDeletedVideoId(null);
        setShowDeleteWarning(false);
      })
      .catch((e) => {
        setSelectedDeletedVideoId(null);
        setIsLoading(false);
        console.log(e);
      });
  }
  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <SimpleGrid cols={isMd ? 1 : 4} mt={10}>
        {allRecordings.map((video) => (
          <VideoCard
            video={video}
            onVideoPlayClicked={() => setPlayVideo(video)}
            addVideoToCourse={(video:CourseVideo) => {
              setSelectedVideo(video);
            }}
            onDeleteVideoClicked={() => {
              setShowDeleteWarning(true);
              setSelectedDeletedVideoId(video._id);
            }}
            showOptions={props.userType !== UserType.STUDENT}
            isVideoSelect={props.isVideoSelect}
            selectedVideos={selectedVideos}
            onSelectRecording={(
              val: {
                name: string;
                url: string;
              },
              isChecked: boolean
            ) => {
              if (isChecked) {
                setSelectedVideos((prev) => [...prev, val]);
              } else {
                setSelectedVideos((prev) =>
                  prev.filter((v) => v.url !== val.url)
                );
              }
            }}
          />
        ))}
      </SimpleGrid>
      {props.isVideoSelect && (
        <Flex w={"100%"} align={"center"} justify={"center"} mt={20}>
          <Button
          w={"20%"}
            onClick={() => {
              props.onClickSubmit && props.onClickSubmit(selectedVideos);
            }}
            variant="outline"
          >
            Submit
          </Button>
        </Flex>
      )}
      <Modal
        opened={playVideo !== null}
        onClose={() => setPlayVideo(null)}
        title={playVideo?.name}
        centered
        size="xl"
        styles={{
          title: {
            fontWeight: 700,
            fontSize: 20,
          },
        }}
       
      >
        <video
          src={playVideo?.url}
          controls
          style={{
            width: "100%",
          }}
          controlsList={
            props.userType === UserType.STUDENT ? "nodownload" : "nodownload"
          }
          preload="none"
        ></video>
      </Modal>
      <Modal
        opened={selectedVideo !== null}
        onClose={() => setSelectedVideo(null)}
        title="Add To Course"
        centered
        zIndex={1235}
      >
        <Stack>
          <Select
            value={selectedCourse}
            data={allCourses}
            onChange={(val) => {
              if (val) setSelectedCourse(val);
            }}
            placeholder="Select Course"
          />
          <Flex justify="right">
            <Button
              variant="outline"
              style={{
                border: "1px solid #808080",
                borderRadius: "30px",
                color: "#000",
              }}
              size={isMd ? "sm" : "lg"}
              mr={10}
              onClick={() => {
                setSelectedVideo(null);
              }}
            >
              Cancel
            </Button>
            <Button
              bg="#4B65F6"
              size={isMd ? "sm" : "lg"}
              style={{
                border: "1px solid #808080",
                borderRadius: "30px",
              }}
              sx={{
                "&:hover": {
                  background: "#4B65F6",
                },
                "&:disabled": {
                  opacity: 0.3,
                  background: "#4B65F6",
                },
              }}
              onClick={() => {
                handleSubmit();
              }}
              leftIcon={<IconPlus />}
              disabled={selectedCourse.trim().length < 1}
            >
              Add Video
            </Button>
          </Flex>
        </Stack>
      </Modal>
      <Modal
        opened={showDeleteWarning}
        onClose={() => {
          setShowDeleteWarning(false);
        }}
        centered
        style={{ zIndex: 9999999 }}
        title="Delete Recroding"
      >
        <Stack>
          <Text>{`Are you sure you want to delete this recording?`}</Text>
          <Group>
            <Button
              color="#909395"
              fz={16}
              fw={500}
              style={{
                border: "#909395 solid 1px",
                color: "#909395",
              }}
              variant="outline"
              size="lg"
              w="47%"
              onClick={() => {
                setShowDeleteWarning(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#FF0000",
                color: "white",
              }}
              size="lg"
              w="47%"
              fz={16}
              fw={500}
              onClick={() => {
                if (selectedDeletedVideoId)
                  handleRecordingDelete(selectedDeletedVideoId);
              }}
            >
              Yes,Delete it
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}
