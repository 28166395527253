import ApiHelper from "../../utilities/ApiHelper";

export function UploadInstituteVideoRecording(data: {  id: string , url:string, name:string , userId:string }) {
    return new Promise((resolve, reject) => {
      ApiHelper.post(`/api/v1/videoRecording/instituteVideoRecording/${data.id}`,{
        url:data.url,
        name:data.name,
        userId:data.userId
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  
  export function GetUserVideoRecordings(id:string) {
    return new Promise((resolve, reject) => {
      ApiHelper.get(`/api/v1/videoRecording/getUserVideoRecordings/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  export function DeleteUserVideoRecordings(id:string) {
    return new Promise((resolve, reject) => {
      ApiHelper.put(`/api/v1/videoRecording/deleteUserVideoRecordings/${id}`,{})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }